<template>
  <div>
    <h4 class="ml-2">{{ name }}</h4>
    <v-form ref="form">
      <v-container>
        <v-row class="mt-4">
          <v-col cols="12" md="8">
            <label>Public Transport Connections</label>
            <v-item-group v-model="form.ptcs" multiple>
              <v-container>
                <v-row>
                  <v-col v-for="(hotelPtc, index) in hotelPtcs" :key="index" class="m-0 p-0 custom-item-column">
                    <v-item :value="hotelPtc">
                      <v-card
                        :color="isPublicTransportConnectionSelected(hotelPtc) ? 'primary' : 'default'"
                        class="d-flex align-center"
                        dark
                        height="75"
                        width="75"
                        @click="setActive(hotelPtc)"
                        outlined
                      >
                        <v-scroll-y-transition>
                          <div
                            class="flex-grow-1 text-center"
                            :class="{ 'black--text': isPublicTransportConnectionSelected(hotelPtc) === false }"
                          >
                            <v-icon
                              :color="isPublicTransportConnectionSelected(hotelPtc) === false ? 'primary' : ''"
                              :class="[hotelPtc.icon]"
                            ></v-icon>
                            <div>
                              <small>{{ hotelPtc.name }}</small>
                            </div>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="form.vendor_specifications.total_parking_spaces"
              :rules="[getRules.required, getRules.nonNegativeNumber, getRules.number]"
              min="0"
              label="Amount of Parking Space"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="default px-3 py-2" outlined
              >What is the total number of parking spaces for the hotel?</v-card
            >
          </v-col>
        </v-row>

        <!--Office Tours & Activities (START)-->
        <v-row class="mt-3 mb-0">
          <v-col cols="12" md="8" class="my-0 py-0">
            <v-card class="bg-color-grey px-3 py-2" outlined>
              <v-row class="my-0 py-0">
                <v-col cols="12" md="1" class="my-0 py-0">
                  <v-checkbox class="my-0 py-0" v-model="form.vendor_specifications.has_activities"></v-checkbox>
                </v-col>
                <v-col cols="12" md="11" class="my-0 py-0">
                  <v-row class="my-0 py-0">
                    <v-col cols="12" md="12" class="my-0 py-0">
                      Tours & Activities
                    </v-col>
                    <v-col v-if="form.vendor_specifications.has_activities" cols="12" md="12" class="my-0 py-0">
                      <v-row
                        class="my-0 py-0"
                        v-for="(activity, activityIndex) in form.activities"
                        :key="'esi' + activityIndex"
                      >
                        <v-col cols="12" md="10" class="my-0 py-0">
                          <v-text-field
                            v-model="form.activities[activityIndex].name"
                            label="Name of tour & activity"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" class="my-0 py-0">
                          <v-icon
                            class="pt-5 my-0 mdi mdi-close-circle pointer"
                            @click="removeActivity(activityIndex)"
                          ></v-icon>
                        </v-col>
                      </v-row>

                      <v-row class="my-0 py-0">
                        <v-col cols="12" md="12">
                          <div class="pointer text--secondary" @click="addTourAndActivity">
                            <i class="mdi mdi-plus"></i> Add tour & activity
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="my-0 py-0">
            <v-card class="default px-3 py-2" outlined>Add tours & activities</v-card>
          </v-col>
        </v-row>
        <!--Office Tours & Activities (END)-->
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mr-3 mt-5">Continue</v-btn>
    <v-btn
      color="secondary"
      class="mr-1 float-right mt-5"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SurroundingsAndActivities",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    hotelPtcs: [
      {
        name: "Bus",
        icon: "mdi mdi-bus"
      },
      {
        name: "Train",
        icon: "mdi mdi-train"
      },
      {
        name: "Subway",
        icon: "mdi mdi-subway"
      },
      {
        name: "Ferry",
        icon: "mdi mdi-ferry"
      },
      {
        name: "Water Taxi",
        icon: "mdi mdi-ship-wheel"
      }
    ]
  }),
  computed: {
    ...mapGetters(["getRules", "getHotelErrors", "getVendor", "getIsVendorUpdate"])
  },
  methods: {
    setActive(ptc) {
      if (this.form.ptcs && this.form.ptcs.length > 0) {
        if (this.form.ptcs.some(pt => pt.name === ptc.name)) {
          this.form.ptcs = this.form.ptcs.filter(item => item.name !== ptc.name);
        } else {
          let publicTransport = [];
          this.form.ptcs.forEach(p => {
            publicTransport.push(p);
          });
          this.form.ptcs = [];
          publicTransport.push(ptc);
          publicTransport.forEach(arrangement => {
            if (arrangement.name.replace(/ /g, "") !== "") {
              this.form.ptcs.push({
                name: arrangement.name,
                icon: arrangement.icon
              });
            }
          });
        }
      } else {
        this.form.ptcs = [];
        this.form.ptcs.push(ptc);
      }
    },
    isPublicTransportConnectionSelected(locationPtc) {
      let isSelected = false;
      if (this.form.ptcs) {
        this.form.ptcs.forEach(ptc => {
          if (ptc.name === locationPtc.name) {
            isSelected = true;
          }
        });
      }
      return isSelected;
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("hotelNextStep", this.index);
    },
    addTourAndActivity() {
      this.form.activities.push({
        name: null,
        removable: true,
        editable: false
      });
    },
    removeActivity(activityIndex) {
      this.form.activities = _.reject(this.form.activities, (activity, index) => {
        return activityIndex === index;
      });
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}
.pointer {
  cursor: pointer;
}
</style>
